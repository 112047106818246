<template>
  <page>
    <v-card light rounded="lg" class="pa-10 text-center">
      {{ result }}
    </v-card>
  </page>
</template>

<script>
import {Component, Mixins, Vue} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page";
import {DocumentsByContract} from "@/models/contract";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: { Page }
})
class SuccessPayment extends Mixins(AppApiMixin) {
  result = ''
  async mounted(){
    const order = this.$route.query?.order || 0
    const result = await this.checkPay(Number.parseInt(order));
    this.result = result.message
  }
}
export default SuccessPayment;
</script>

<style scoped>

</style>
